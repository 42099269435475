var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[(_vm.task.content.config.heading)?_c('h2',{staticClass:"mb-2"},[_c('stemble-latex',{staticStyle:{"white-space":"pre-wrap"},attrs:{"content":_vm.task.content.config.heading}})],1):_vm._e(),_vm._l((_vm.task.content.config.uiContext),function(uiContextItem){return _c('div',{key:uiContextItem.uuid},[(uiContextItem.contextType === 'text_block')?_c('div',[_c('text-block-ui-component',{attrs:{"text":uiContextItem.text}})],1):(uiContextItem.contextType === 'multiple_choice')?_c('div',[_c('v-radio-group',{staticClass:"pl-3",attrs:{"label":_vm.task.content.config.text},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('stemble-latex',{staticStyle:{"white-space":"pre-wrap"},attrs:{"content":uiContextItem.text}})]},proxy:true}],null,true),model:{value:(_vm.inputs[uiContextItem.uuid]),callback:function ($$v) {_vm.$set(_vm.inputs, uiContextItem.uuid, $$v)},expression:"inputs[uiContextItem.uuid]"}},_vm._l((uiContextItem.multipleChoiceOptions),function(option){return _c('v-radio',{key:option.key,attrs:{"value":option.key},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('stemble-latex',{attrs:{"content":option.label}})]},proxy:true}],null,true)})}),1)],1):(uiContextItem.contextType === 'randomized_passage')?_c('div',[_c('stemble-latex',{staticStyle:{"white-space":"pre-wrap"},attrs:{"content":uiContextItem.randomTextPassages[
            _vm.examplePostNumber.value % uiContextItem.randomTextPassages.length
          ]}})],1):(uiContextItem.contextType === 'dynamic_table')?_c('div',[_c('dynamic-table-input',{staticClass:"mb-4",attrs:{"is-marking":_vm.isMarking,"enable-editable-headers":uiContextItem.allowEditableHeaders,"enable-dynamic-columns":uiContextItem.allowDynamicColumns},model:{value:(_vm.inputs[uiContextItem.uuid]),callback:function ($$v) {_vm.$set(_vm.inputs, uiContextItem.uuid, $$v)},expression:"inputs[uiContextItem.uuid]"}})],1):(uiContextItem.contextType === 'student_response')?_c('div',[_c('s-textarea',{attrs:{"outlined":"","counter":uiContextItem.characterLimit > 0,"maxlength":uiContextItem.characterLimit > 0 ? uiContextItem.characterLimit : undefined},model:{value:(_vm.inputs[uiContextItem.uuid]),callback:function ($$v) {_vm.$set(_vm.inputs, uiContextItem.uuid, $$v)},expression:"inputs[uiContextItem.uuid]"}})],1):(uiContextItem.contextType === 'file_upload')?_c('div',[_c('file-upload-ui-component',_vm._b({attrs:{"max-file-size":uiContextItem.maxFileSize,"max-number-of-files":uiContextItem.maxNumberOfFiles,"show-accepted-file-types":"","show-max-file-size":""},on:{"upload:attachments":function($event){_vm.attachments = $event}}},'file-upload-ui-component',
          (uiContextItem.acceptedFileExtensions || uiContextItem.text) && {
            acceptedFileExtensions: !!uiContextItem.acceptedFileExtensions
              ? uiContextItem.acceptedFileExtensions
              : uiContextItem.text,
          }
        ,false))],1):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }